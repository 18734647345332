<template>

  <div>

    <div class="item__row item__ac">

      <h2>Наша команда</h2>

      <v-btn
          small
          class="mx-2"
          fab
          dark
          color="#1f2b45"
          @click="chooseTypeFunction(1)"
      >
        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn>

    </div>

    <div class="item__column  pa-4 mb-2 news__list" v-for="item in items" :key="item.id">

      <div  class="item__row item__ac">

        <div v-for="image in item.images"  :key="image.id" >

          <img  :src="'https://api.kazaerospace.kz/'+image.path" />
        </div>


      </div>
      <p class="mb-2">{{ item.name }}</p>
      <p class="mb-2">{{ item.responsible }}</p>
      <p class="mb-2">{{ item.name_kaz }}</p>
      <p class="mb-2">{{ item.responsible_kaz }}</p>
      <p class="mb-2">{{ item.name_eng }}</p>
      <p class="mb-2">{{ item.responsible_eng }}</p>
      <div class="item__row item__ac">
        <v-btn
            small
            class="mx-2 mr-2"
            fab
            dark
            color="#1f2b45"
            @click="show(item)"
        >
          <v-icon dark>
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn
            v-if="me && me.role.role == 'admin'"
            small
            class="mx-2 mr-2"
            fab
            dark
            @click="openDeleteModal(item.id)"
            color="#1f2b45"
        >
          <v-icon dark>
            mdi-trash-can-outline
          </v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

    </div>
    <v-dialog v-model="destroyModal" width="750">
      <v-card class="pa-6">
        <h3 class="mb-4">Удалить запись</h3>
        <v-btn
            type="submit"
            depressed
            color="primary"
            @click="deleteItem()"
        >
          Да
        </v-btn>

        <v-btn
            depressed
            color="default"
            @click="destroyModal=false"
        >
          Отмена
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog v-model="newsModal" width="750">
      <v-card class="pa-6">
        <v-form
            @submit.prevent="callFunction()"
            ref="form"
            class="sign__page__block"
        >

          <h3 class="mb-4" v-if="type==1">Создать</h3>
          <h3 class="mb-4" v-else>Редактировать </h3>
          <div class="item__column">
            <v-text-field
                v-model="title"
                label="ФИО"
                required
                outlined
                class="input"
                :rules="nameRules"
            ></v-text-field>
          </div>
          <div class="item__column">
            <v-textarea
                v-model="description"
                filled
                name="input-7-4"
                label="Должность"

                :rules="descriptionRules"

            ></v-textarea>
          </div>

          <div class="item__column">
            <v-text-field
                v-model="title_kaz"
                label="ФИО на казахском"
                required
                outlined
                class="input"
                :rules="nameRules"
            ></v-text-field>
          </div>
          <div class="item__column">
            <v-textarea
                v-model="description_kaz"
                filled
                name="input-7-4"
                label="Должность на казахском"

                :rules="descriptionRules"

            ></v-textarea>
          </div>
          <div class="item__column">
            <v-text-field
                v-model="title_eng"
                label="ФИО английском"
                required
                outlined
                class="input"
                :rules="nameRules"
            ></v-text-field>
          </div>
          <div class="item__column">
            <v-textarea
                v-model="description_eng"
                filled
                name="input-7-4"
                label="Должность английском"

                :rules="descriptionRules"

            ></v-textarea>
          </div>

       
          <div>
            <v-file-input
                chips
                counter
                multiple
                show-size
                small-chips
                truncate-length="15"
                v-model="files"
            ></v-file-input>
          </div>

          <div class="item__column">
              <div v-for="file in uploadedFiles" :key="file.id" class="item__row item__ac pointer mb-3">
                  <p class="mr-2 mb-0">{{file.path.split('/')[file.path.split('/').length-1]}}</p>
                  <i class="mdi mdi-trash-can-outline" @click="removeFile(file.id)"></i>
              </div>
          </div>

          <v-btn
              type="submit"
              depressed
              color="primary"
          >
            Сохранить изменения
          </v-btn>

          <v-btn
              depressed
              color="default"
              @click="newsModal=false"
          >
            Отмена
          </v-btn>
        </v-form>



      </v-card>
    </v-dialog>

  </div>

</template>

<script>

export default {
  name: "Team",
  data() {
    return {
      items: [],
      newsModal: false,
      destroyModal: false,
      title: '',
      description: '',
      title_kaz: '',
      description_kaz: '',
      title_eng: '',
      description_eng: '',
      nameRules: [
        v => !!v || 'Заполните поле'
      ],
      descriptionRules: [
        v => !!v || 'Заполните поле'
      ],
      files: [],
      type: 0,
      idItem:'',
      me: null,
      selectedUser: null,
      uploadedFiles: []
    };
  },
  methods: {
      removeFile(fileId) {
            this.$axios({
                method: "delete",
                url:
                this.$API_URL +
                this.$API_VERSION +
                "team/file/"+fileId,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
            .then((response) => {
                this.$toast.open({
                    message: response.data.message,
                    type: "success",
                    position: "bottom",
                    duration: 4000,
                    queue: true,
                });
                this.fetch();
                this.newsModal = false;
            })
            .catch((error) => {
                console.warn(error);
            });
    },
    getUser() {
      this.$axios({
        method: "get",
        url:
            this.$API_URL +
            this.$API_VERSION +
            "me",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
          .then((response) => {
            this.me = response.data;
          })
          .catch((error) => {
            console.warn(error);
          });
    },
    chooseTypeFunction(type) {
      this.type = type;
      this.newsModal = true;
    },
    callFunction() {
      this.type==1?this.create():this.update();
    },
    create() {
      let contractForm = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        contractForm.append("images[]", this.files[i]);
      }

      contractForm.append("name", this.title);
      contractForm.append("responsible", this.description);
      contractForm.append("name_eng", this.title_eng);
      contractForm.append("responsible_eng", this.description_eng);
      contractForm.append("name_kaz", this.title_kaz);
      contractForm.append("responsible_kaz", this.description_kaz);


      this.$axios
          .post(this.$API_URL + this.$API_VERSION + "team/store", contractForm, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {

            this.files = [];
            this.title = null;
            this.description = null;


            console.log(response);
            this.$toast.open({
              message: "Успешно создано",
              type: "success",
              position: "bottom",
              duration: 4000,
              queue: true,
            });

            this.newsModal = false;
            this.type = 0;
            this.$refs.form.reset();
            this.fetch();

          })
          .catch((error) => {
            if (error.response && error.response.status == 422) {
              this.$toast.open({
                message: "Заполните все поля",
                type: "error",
                position: "bottom",
                duration: 4000,
                queue: true,
              });

            }
          });
    },
    show(item) {
      this.idItem = item.id;
      this.newsModal = true;
      this.title = item.name;
      this.description = item.responsible;
      this.title_kaz = item.name_kaz;
      this.description_kaz = item.responsible_kaz;
      this.title_eng = item.name_eng;
      this.description_eng = item.responsible_eng;
      this.newsModal = true;

      this.uploadedFiles = item.images;
    },
    openDeleteModal(item) {
      this.destroyModal = true;
      this.idItem = item;
    },
    deleteItem() {
      this.$axios({
        method: "delete",
        url:
            this.$API_URL +
            this.$API_VERSION +
            "delete/team/"+this.idItem,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
          .then((response) => {
            this.title = response.data.title;
            this.description = response.data.description;
            this.fetch();
            this.destroyModal = false
          })
          .catch((error) => {
            console.log(error);
          });
    },
    update() {
      let contractForm = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        contractForm.append("images[]", this.files[i]);
      }

      contractForm.append("name", this.title);
      contractForm.append("responsible", this.description);
      contractForm.append("name_kaz", this.title_kaz);
      contractForm.append("responsible_kaz", this.description_kaz);
      contractForm.append("name_eng", this.title_eng);
      contractForm.append("responsible_eng", this.description_eng);


      this.$axios
          .post(this.$API_URL + this.$API_VERSION + "show/team/" + this.idItem, contractForm, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.files = [];
            this.title = null;
            this.description = null;
            console.log(response);
            this.$toast.open({
              message: "Успешно создано",
              type: "success",
              position: "bottom",
              duration: 4000,
              queue: true,
            });

            this.newsModal = false;

            this.fetch();
          })
          .catch((error) => {
            if (error.response && error.response.status == 422) {
              this.$toast.open({
                message: "Заполните все поля",
                type: "error",
                position: "bottom",
                duration: 4000,
                queue: true,
              });

            }
          });
    },
    fetch() {
      this.$axios({
        method: "get",
        url:
            this.$API_URL +
            this.$API_VERSION +
            "team",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
          .then((response) => {
            this.items = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
    }
  },
  mounted() {
    this.fetch();
    this.getUser();
  },
  beforeMount() {

  },
  watch: {
      newsModal(val) {
        if(val==false) {
            this.title_kaz = '';
            this.title_eng = '';
            this.title = '';
            this.description = '';
            this.description_eng = '';
            this.description_kaz = '';
            this.files = [];
            this.uploadedFiles = [];
            this.$refs.form.reset();
            
        }
      }


  },
};
</script>


<style lang="scss">
.news__list {
  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
}
</style>
